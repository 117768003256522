const AnimationEnterTraitEpais = ({props}) => {
	return (
		<svg
			width='2740'
			height='1780'
			viewBox='0 0 2740 1780'
			xmlns='http://www.w3.org/2000/svg'

			className={'animation_enter_trait_epais'}

			fill={'none'}
			stroke='#3a4156'
		>
			<g clipPath='url(#clip0_19_5)'>
				<path
					d='M29.979 1097.09C92.583 1111.93 401.758 1178.47 664.707 990.928C717.301 953.417 889.949 830.279 919.954 615.964C926.426 569.736 951.589 390.003 831.86 287.094C703.391 176.673 467.451 205.709 377.837 335.87C316.677 424.702 326.637 556.333 377.837 649.846C537.153 940.824 1114.62 897.168 1366.72 878.11C1681.02 854.35 2019.8 828.738 2302.35 561.752C2497.37 377.473 2578.67 160.345 2614.07 35.4468'
					strokeWidth='260'
					strokeMiterlimit='10'
				/>
			</g>
			<defs>
				<clipPath id='clip0_19_5'>
					<rect
						width='2739.14'
						height='1250.67'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>

	)
}

export default AnimationEnterTraitEpais
