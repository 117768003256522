const AnimationTraitEpais = ({props}) => {
	return <svg
		width='1817'
		height='976'
		viewBox='0 0 1817 976'
		xmlns='http://www.w3.org/2000/svg'

		className={'animation_trait_epais'}

		fill='none'
		stroke='#3a4156'
	>
		<g clipPath='url(#clip0_14_2)'>
			<path
				d='M790.968 845.962C417.023 800.953 151.908 637.286 132.239 455.225C125.683 394.537 145.331 321.913 186.894 266.569C253.149 178.346 367.398 142.53 608.897 136.089C1067.4 123.861 1357.22 116.132 1545.38 331.666C1664.74 468.399 1713.51 655.15 1672.68 831.641'
				strokeWidth='260'
				strokeMiterlimit='10'
			/>
		</g>
	</svg>

}

export default AnimationTraitEpais
