const AnimationLogoLetterO = ({props}) => {
	return <svg
		id='b'
		data-name='Calque 2'
		xmlns='http://www.w3.org/2000/svg'
		width='99.01'
		height='78.807'
		viewBox='0 0 99.01 78.807'

		fill='none'
		stroke='#3a4156'

		className={'letter_o'}

		{...props}
	>
		<g
			id='c'
		>
			<path
				d='m7.963,33.647c-.426,3.486-.604,9.099,1.653,15.248,4.199,11.437,15.922,22.276,30.871,22.212,10.89-.047,21.082-5.87,26.729-14.682,8.8-13.731,6.044-33.902-6.353-43.106-7.444-5.526-19.839-8.193-28.8-2.259-4.377,2.899-9.657,8.99-8.894,16,1.02,9.376,12.159,14.058,14.541,15.059,14.378,6.043,34.32.9,45.176-12.847,7.651-9.688,8.416-20.646,8.424-25.553'
				strokeMiterlimit='10'
				strokeWidth='15.4'
			/>
		</g>
	</svg>
}

export default AnimationLogoLetterO
