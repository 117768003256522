import Router      from 'next/router'
import {useEffect} from 'react'

export const OPACITY_EXIT_DURATION = 1

const routeChange = () => {
	const tempFix = () => {
		// Get path name and asPath from router
		const pathname = Router.router?.pathname
		const asPath   = Router.router?.asPath

		// If pathname is 404
		if (Router.router?.pathname
		    !== '/404') {
			const elements = document.querySelectorAll('style[media="x"]')
			elements.forEach((elem) => elem.removeAttribute('media'))

			setTimeout(
				() => {
					elements.forEach((elem) => elem.remove())
				},
				OPACITY_EXIT_DURATION
				* 1000,
			)
		}
	}
	tempFix()
}

export const usePageTransitionFix = () => {
	console.debug('WARNING: Still using FOUC temp fix on route change.  Has the Next.js bug not been fixed?  See https://github.com/vercel/next.js/issues/17464')
	useEffect(
		() => {
			Router.events.on(
				'routeChangeComplete',
				routeChange,
			)
			Router.events.on(
				'routeChangeStart',
				routeChange,
			)

			return () => {
				Router.events.off(
					'routeChangeComplete',
					routeChange,
				)
				Router.events.off(
					'routeChangeStart',
					routeChange,
				)
			}
		},
		[],
	)

	useEffect(
		() => {
			if (Router.router?.pathname
			    !== '/404') {
				const asPath = Router.router?.asPath
				Router.router?.replace(asPath)
				// ? Use replace() instead of push()?
			} else {
				Router.router?.replace(Router.router.pathname)
			}
		},
		[],
	)
}
