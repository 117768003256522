import '../styles/globals.sass';
import Head from 'next/head';
import gsap, {Power0} from 'gsap/dist/gsap';
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger';
import {ScrollSmoother} from 'gsap/dist/ScrollSmoother';
import {ScrollToPlugin} from 'gsap/dist/ScrollToPlugin';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import qs from 'qs';
import {AnimatePresence, motion} from 'framer-motion';
import {useRouter} from 'next/router';
import AnimationLogo from '../components/svg/animation/AnimationLogo';
import AnimationTraitFin from '../components/svg/animation/AnimationTraitFin';
import AnimationTraitEpais from '../components/svg/animation/AnimationTraitEpais';
import {DrawSVGPlugin} from 'gsap/dist/DrawSVGPlugin';
import {usePageTransitionFix} from '../components/use-page-transition-fix';
import AnimationLogoLetterO from '../components/svg/animation/AnimationLogoLetterO';
import Script from 'next/script';
import AnimationEnterTraitEpais from '../components/svg/animation/AnimationEnterTraitEpais';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);
    gsap.registerPlugin(DrawSVGPlugin);
}

//region Scroll smoother
const handleKeyDown = (event) => {
    if ((
        event.metaKey && navigator.platform.includes('Mac')
    ) || (
        event.ctrlKey && !navigator.platform.includes('Mac')
    )) {
        window.scroller?.paused(true);
    }
};
const handleKeyUp = (event) => {
    if ((
        !event.metaKey && navigator.platform.includes('Mac')
    ) || (
        !event.ctrlKey && !navigator.platform.includes('Mac')
    )) {
        window.scroller.paused(false);
    }
};
export const addScrollSmoother = (setScroller) => {

    let scrollSmoother = true;
    if (window.innerWidth > 1024) {
        scrollSmoother = ScrollSmoother.create({
            wrapper: '#smooth-wrapper',
            content: '#smooth-content',
            smooth: 1,
            smoothTouch: false,
            effects: false,

            normalizeScroll: true,
            ignoreMobileResize: true,
        });

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
    }

    window.scroller = scrollSmoother;

    setScroller(scrollSmoother);

    return () => {
        if (typeof scrollSmoother !== 'boolean') {
            scrollSmoother?.kill();


            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        }
    };
};
//endregion

const colors = [
    '#3B4156', '#D36F3B', '#6BAF91', '#611A1E', '#F5AE39',
];

function MyApp({
                   Component,
                   pageProps,
               }) {

    usePageTransitionFix();

    // TODO: RESET
    // const [firstLoad, setFirstLoad] = useState(true)
    const [firstLoad, setFirstLoad] = useState(process.env.NODE_ENV === 'production');

    const router = useRouter();

    useEffect(() => {
        library.add(fab);
    }, []);

    //region Page transition
    const changePageTransitionColor = () => {
        const newColors = colors.filter(c => c !== pageTransitionColor);
        return newColors[Math.floor(Math.random() * (
            newColors.length
        ))];
    };
    const [pageTransitionColor, setPageTransitionColor] = useState(colors[1]);

    const pageTransitionAnimate = {
        y: '-100%',
        transition: {
            ease: 'easeIn',
            duration: .5,
        },
    };
    const isAnimationEnter = (definition) => {
        return definition === pageTransitionAnimate;
    };
    //endregion

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />

                {/*TODO: Change title*/}
                <title>income</title>
                <meta
                    name={'description'}
                    content={'income accompagne les dirigeants dans toutes les décisions qui engagent l\'avenir financier de leur organisation.'}
                />
                <link
                    rel="icon"
                    href="/favicon.png"
                />

                <link
                    rel="dns-prefetch"
                    href="https://www.income.com"
                />

                {/*region SEO*/}
                <link
                    rel="profile"
                    href="https://gmpg.org/xfn/11"
                />
                <meta
                    name="robots"
                    content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                />
                <meta
                    name="googlebot"
                    content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
                />
                <meta
                    name="language"
                    content="fr"
                />
                <meta
                    name="revisit-after"
                    content="7 days"
                />
                {/*region OG*/}
                <meta
                    property="og:locale"
                    content="fr_FR"
                />
                <meta
                    property="og:type"
                    content="website"
                />
                <meta
                    property="og:site_name"
                    content="income"
                />
                {/*<meta
				 property='og:image'
				 content='https://www.income.com/logo.png'
				 />*/}
                {/*endregion*/}
                {/*endregion*/}
            </Head>

            <AnimatePresence
                mode={'wait'}
                initial={false}
            >
                <motion.div
                    className={'page_transition_overlay'}
                    key={`page_transition_overlay_${router.asPath}`}
                    initial={{y: '0%'}}
                    animate={pageTransitionAnimate}
                    exit={{
                        y: [
                            '-100%', '0%', '0%',
                        ],
                        transition: {
                            ease: 'easeOut',
                            duration: 1,
                        },
                    }}

                    onAnimationStart={definition => {
                        if (!isAnimationEnter(definition)) {
                            gsap.timeline({
                                defaults: {
                                    delay: .5,
                                    duration: .3,
                                    ease: Power0.easeNone,
                                },
                            })
                                .fromTo('.page_transition_overlay .letter_o path', {drawSVG: 0}, {
                                    drawSVG: '100%',
                                });
                        }
                    }}

                    onAnimationComplete={definition => {
                        // If the animation is the animate one (not the exit one) -> Change background color
                        if (isAnimationEnter(definition)) {
                            setPageTransitionColor(changePageTransitionColor);
                        } else {
                            window.scrollTo(0, 0);
                        }
                    }}

                    style={{
                        visibility: firstLoad
                            ? 'hidden'
                            : 'visible',
                        backgroundColor: pageTransitionColor,
                    }}
                >
                    <AnimationLogoLetterO className={'letter_o'}/>
                </motion.div>

                {<EnterAnimation
                    firstLoad={firstLoad}
                    setFirstLoad={setFirstLoad}
                />}
                <motion.div
                    key={`component_container${router.asPath}`}
                    initial={{visibility: 'hidden'}}
                    animate={{
                        visibility: firstLoad
                            ? 'hidden'
                            : 'visible',
                    }}
                >
                    <Component {...pageProps} firstLoad={firstLoad}/>
                </motion.div>
            </AnimatePresence>

            <Script
                strategy={'lazyOnload'}
                id={'ziben-matomo'}
            >
                {`
					var _paq = window._paq = window._paq || []
					/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
					_paq.push(['trackPageView'])
					_paq.push(['enableLinkTracking']);
					(function() {
					var u='//www.matomo.admin-ziben.fr/'
					_paq.push(['setTrackerUrl', u+'matomo.php'])
					_paq.push(['setSiteId', '24'])
					var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]
					g.async=true
					g.src=u+'matomo.js'
					s.parentNode.insertBefore(g,s)
					})()
				`}
            </Script>
        </>
    );
}

export default MyApp;

export const getFooterData = async () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.API_TOKEN}`;

    let data = null;

    const query = qs.stringify({
        fields: [
            'legal_notices',
        ],
        populate: {
            links: {
                fields: [
                    'title', 'link',
                ],
                populate: {
                    links: {
                        fields: [
                            'title', 'link', 'bold',
                        ],
                    },
                },
            },
            socials: {
                fields: [
                    'name', 'link',
                ],
            },
            section_newsletter: {
                fields: ['title'],
                populate: {
                    inputs: {
                        fields: [
                            'name', 'placeholder',
                        ],
                    },
                },
            },
        },
    }, {encodeValuesOnly: true});


    await axios.get(`${process.env.NEXT_PUBLIC_SITE_API_LINK}/api/section-pied-de-page?${query}`)
        .then(res => {
            data = res.data.data.attributes;
        });

    return data;
};

const EnterAnimation = ({
                            firstLoad,
                            setFirstLoad,
                        }) => {

    const [showAnimation, setShowAnimation] = useState(firstLoad);

    useEffect(() => {
        if (showAnimation) {
            const mm = gsap.matchMedia();
            mm.add({
                isDesktop: '(min-width: 1025px)',
                isMobile: '(max-width: 1025px)',
            }, context => {
                let {
                    isDesktop,
                    isMobile,
                } = context.conditions;

                const timeline = gsap.timeline({
                    id: 'animationTimeline',
                    defaults: {
                        duration: .25,
                        ease: Power0.easeNone,
                    },
                });

                timeline
                    // Show animation
                    .to('.animation', {
                        visibility: 'visible',
                    })
                    // Draw trait epais enter
                    .fromTo('.animation_enter_trait_epais path', {drawSVG: 0}, {
                        drawSVG: '100%',
                        duration: isDesktop
                            ? .5
                            : .25,
                    })
                    // Show logo
                    .to('.animation_logo', {
                        visibility: 'visible',
                    })
                    // Remove trait epais enter
                    .to('.animation_enter_trait_epais path', {
                        drawSVG: '100% 100%',
                        duration: isDesktop
                            ? .25
                            : .2,
                    })
                    // Draw trait fin
                    .fromTo('.animation_trait_fin path', {drawSVG: 0}, {
                        drawSVG: '100%',
                        duration: .5,
                    }, '<+50%')
                    .fromTo('.o_and_m', {
                        drawSVG: '0%',
                    }, {
                        drawSVG: '100%',
                        duration: .25,
                    })
                    .to('.animation_trait_fin path', {drawSVG: '100% 100%'})
                    .fromTo('.animation_trait_epais path', {drawSVG: 0}, {
                        drawSVG: '100%',
                        duration: isDesktop
                            ? .5
                            : .25,
                    }, '>')
                    .set('.animation_logo', {css: {display: 'none'}})
                    .to('.animation_trait_epais path', {
                        drawSVG: '100% 100%',
                        duration: isDesktop
                            ? .25
                            : .2,
                    })
                    .call(() => {
                        setFirstLoad(false);
                    }, null, '>')
                    .to('.animation', {
                        opacity: 0,
                        duration: .5,
                    })
                    .set('.animation', {
                        display: 'none',
                    });

                return () => {
                    timeline.kill();
                };
            });
        }
    }, [
        setFirstLoad, showAnimation,
    ]);

    return showAnimation && <div className={'animation'}>
        <AnimationLogo className={'logo'}/>
        <AnimationTraitFin/>
        <AnimationEnterTraitEpais/>
        <AnimationTraitEpais/>
    </div>;
};
