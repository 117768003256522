const AnimationTraitFin = ({props}) => {
	return <svg
		id='b'
		data-name='Calque 2'
		xmlns='http://www.w3.org/2000/svg'
		width='944.843'
		height='492.306'
		viewBox='0 0 944.843 492.306'

		className={'animation_trait_fin'}

		fill={'none'}
		stroke='#3a4156'
	>
		<g id='c'>
			<path
				d='m.537,194.578c45.478,3.178,120.25,8.268,218.894,14.32,56.083,3.441,92.214,5.208,132.973-14.32,17.641-8.452,82.121-39.345,81.83-94.104-.245-46.16-46.608-99.596-102.287-92.058-57.307,7.757-85.797,74.87-74.155,116.056,12.327,43.613,69.69,58.281,123.935,72.152,100.852,25.789,136.55.963,267.31,10.911,93.168,7.088,148.211,12.034,200.483,53.189,45.876,36.119,83.29,95.21,85.012,135.474.123,2.877.572,19.61,1.478,53.076.5,18.497.894,33.498,1.136,42.833'
				strokeMiterlimit='10'
				strokeWidth='15.4'
			/>
		</g>
	</svg>
}

export default AnimationTraitFin
