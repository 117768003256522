const AnimationLogo = ({props}) => {
	return <svg
		width='508'
		height='108'
		viewBox='0 0 508 108'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'

		className={'animation_logo'}

		{...props}
	>
		<path
			className={'o_and_m'}
			d='M206.263 61.928C205.837 65.414 205.659 71.027 207.916 77.176C212.115 88.613 223.838 99.452 238.787 99.388C249.677 99.341 259.869 93.518 265.516 84.706C274.316 70.975 271.56 50.804 259.163 41.6C251.719 36.074 239.324 33.407 230.363 39.341C225.986 42.24 220.706 48.331 221.469 55.341C222.489 64.717 233.628 69.399 236.01 70.4C250.388 76.443 270.33 71.3 281.186 57.553C288.837 47.865 289.602 36.907 289.61 32'
			stroke='#3A4156'
			strokeWidth='15.4'
			strokeMiterlimit='10'
		/>

		<path
			d='M20.726 103.949C10.497 103.949 2.314 95.766 2.314 85.537V31.709H19.064V84.131C19.064 87.711 22.005 90.652 25.585 90.652H27.375V103.949H20.726Z'
			fill='#3A4156'
		/>
		<path
			d='M10.6249 21.352C9.98586 21.352 9.34586 21.352 8.83486 21.224C4.48786 20.457 0.779865 16.877 0.140865 12.53C-0.370135 9.334 0.524865 6.265 2.56986 3.836C4.61487 1.407 7.55686 0 10.7529 0C11.3919 0 12.0319 0 12.5429 0.128C16.8899 0.895 20.5979 4.475 21.2369 8.822C21.7479 12.018 20.8529 15.087 18.8079 17.516C16.7619 19.945 13.8219 21.352 10.6249 21.352Z'
			fill='#EB5E2E'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M282.58 31.965H302.782C303.083 32.1734 303.355 32.4112 303.598 32.6608C304.697 33.7896 305.211 35.161 305.211 35.161C305.211 35.161 308.999 29.8138 321.357 29.1019C322.239 29.0512 323.164 29.024 324.134 29.024C324.219 29.024 324.303 29.0242 324.387 29.0245C326.234 29.0324 327.94 29.126 329.514 29.2887C331.34 29.4774 332.989 29.759 334.477 30.1072C334.513 30.1155 334.548 30.124 334.584 30.1324C340.968 31.648 344.361 34.3917 346.02 36.2613C347.04 37.4109 347.404 38.23 347.404 38.23C347.404 38.23 347.855 37.3031 349.07 36.0374C351.29 33.7263 356.062 30.2858 365.3 29.2981C366.946 29.1221 368.734 29.024 370.674 29.024C370.716 29.024 370.759 29.0241 370.801 29.0242C388.26 29.0926 402.383 43.2583 402.383 60.733V104.205H385.633V60.733C385.633 52.55 378.984 45.901 370.801 45.901C370.759 45.901 370.716 45.9012 370.674 45.9015C368.789 45.9174 366.987 46.286 365.332 46.9445C365.321 46.9486 365.311 46.9526 365.301 46.9567C362.406 48.1155 359.96 50.1612 358.299 52.7565C356.824 55.0607 355.969 57.7982 355.969 60.733V104.205H339.219V60.733C339.219 57.8071 338.37 55.0359 336.902 52.6963C335.119 49.8545 332.424 47.6495 329.23 46.5779L329.228 46.5772C329.191 46.565 329.155 46.5529 329.118 46.541C327.584 46.03 325.922 45.774 324.387 45.774C324.303 45.774 324.218 45.7747 324.134 45.7761C322.99 45.7953 321.877 45.9445 320.809 46.2096C320.803 46.2112 320.796 46.2128 320.79 46.2144C317.593 47.0128 314.804 48.8504 312.805 51.3467C311.889 52.4904 311.139 53.7723 310.591 55.1558C309.922 56.8435 309.555 58.6824 309.555 60.606V104.078H292.805V49.738C292.741 40.521 290.071 36.9101 282.58 31.965Z'
			fill='#3A4156'
		/>
		<path
			d='M153.572 105.611C129.407 105.611 114.447 90.907 114.447 67.253C114.447 43.983 129.79 28.895 153.572 28.895C166.23 28.895 176.714 33.114 182.98 40.914C188.35 47.563 190.396 56.641 188.478 65.719H169.555C170.066 63.673 170.706 60.86 170.706 58.303C170.706 53.061 166.998 42.96 152.806 42.96C140.532 42.96 133.244 51.782 133.244 66.486C133.244 82.085 140.276 91.674 152.423 91.674C164.186 91.674 168.917 84.13 170.195 81.701H190.269C188.735 90.395 177.1 105.611 153.574 105.611'
			fill='#3A4156'
		/>
		<path
			d='M450.204 42.833C440.87 42.833 433.327 47.052 431.409 57.025C431.153 58.304 431.153 59.838 431.153 59.838H468.104V57.409C467.465 45.902 456.597 42.833 450.204 42.833ZM450.971 105.612C426.806 105.612 411.846 90.908 411.846 67.254C411.846 43.984 427.189 28.896 450.971 28.896C463.757 28.896 473.73 32.987 479.867 40.659C485.876 48.203 487.922 59.326 485.493 70.45H431.153C431.153 71.601 431.792 91.675 449.82 91.675C461.583 91.675 466.186 84.259 467.592 81.702H487.666C486.132 90.396 474.369 105.612 450.97 105.612'
			fill='#3A4156'
		/>
		<path
			d='M87.469 103.949V60.861C87.469 53.701 81.715 46.924 75.067 45.774C74.3 45.646 73.405 45.518 72.638 45.518C64.455 45.518 57.806 52.167 57.806 60.35V103.822H40.929V60.094C40.929 59.327 40.162 41.938 33.002 31.709H51.03C52.436 32.604 53.587 35.289 53.587 35.289C53.587 35.289 59.341 28.768 72.51 28.768C90.027 28.768 104.219 42.96 104.219 60.477V103.949H87.469Z'
			fill='#3A4156'
		/>
		<path
			d='M505.439 101.648C505.055 102.287 504.416 102.927 503.777 103.31C503.138 103.693 502.371 103.949 501.476 103.949C500.581 103.949 499.814 103.693 499.175 103.31C498.536 102.927 497.896 102.287 497.513 101.648C497.13 101.009 496.874 100.242 496.874 99.347C496.874 98.452 497.13 97.685 497.513 97.046C497.896 96.407 498.536 95.767 499.175 95.384C499.814 95.001 500.581 94.745 501.476 94.745C502.371 94.745 503.138 95.001 503.777 95.384C504.416 95.767 505.056 96.407 505.439 97.046C505.822 97.685 506.078 98.452 506.078 99.347C506.078 100.242 505.822 101.009 505.439 101.648ZM507.229 96.917C506.845 96.15 506.462 95.511 505.95 94.871C505.439 94.36 504.671 93.848 503.904 93.592C503.137 93.208 502.37 93.081 501.475 93.081C500.58 93.081 499.813 93.209 499.046 93.592C498.279 93.975 497.64 94.359 497.128 94.871C496.616 95.383 496.105 96.15 495.849 96.917C495.465 97.684 495.338 98.451 495.338 99.346C495.338 100.241 495.466 101.008 495.849 101.775C496.105 102.542 496.616 103.181 497.128 103.821C497.639 104.332 498.407 104.844 499.046 105.1C499.813 105.484 500.58 105.611 501.475 105.611C502.37 105.611 503.137 105.483 503.904 105.1C504.671 104.717 505.31 104.333 505.822 103.821C506.334 103.309 506.845 102.542 507.101 101.775C507.357 101.008 507.612 100.241 507.612 99.346C507.74 98.451 507.484 97.684 507.228 96.917'
			fill='#EB5E2E'
		/>
		<path
			d='M500.708 97.556H501.603C501.859 97.556 501.987 97.556 502.114 97.684C502.241 97.812 502.37 98.068 502.37 98.323C502.37 98.578 502.242 98.834 502.114 98.962C501.986 99.09 501.73 99.218 501.603 99.218H500.708V97.556ZM503.777 99.346C503.905 99.09 504.033 98.707 504.033 98.323C504.033 97.939 503.905 97.556 503.777 97.3C503.649 97.044 503.393 96.789 503.01 96.661C502.754 96.533 502.371 96.405 501.987 96.405H499.174V102.159H500.708V100.241H501.603L502.498 102.159H504.16L503.009 99.985C503.393 99.729 503.52 99.601 503.776 99.346'
			fill='#EB5E2E'
		/>
	</svg>
}

export default AnimationLogo
